// @flow

import React from "react";
import {
    InspectionDetails as _InspectionDetails,
    AssignmentLayout,
    Layout,
    withPrivateRoute,
} from "@containers";

/**
 * Assignment Preparation
 */
const InspectionDetails = (props: *) => (
    <Layout {...props}>
        <AssignmentLayout title="Opdrachtdetails">
            <_InspectionDetails {...props} />
        </AssignmentLayout>
    </Layout>
);

export default withPrivateRoute(
    InspectionDetails,
    "/opdrachten/:inspectionId/inspectie-details",
);
